import { Player } from '@lottiefiles/react-lottie-player';
import cn from 'classnames';

import { TEMPO } from 'context/BrandContext';
import useBrand from 'hooks/useBrand';
import style from 'styles/components/common/modals/OnboardingModal';

const ModalSlide = ({
  description,
  dskImg,
  isBasketRedesign = false,
  largeHeader,
  mblImg,
  title,
  transitionRef,
}) => {
  const isTempo = useBrand(TEMPO);

  if (isBasketRedesign) {
    return (
      <section className={style.section} ref={transitionRef}>
        <div className={style.basketRedesignImageWrapper}>
          <div
            className={cn(
              style.basketRedesignImageContainer,
              isTempo ? style.tempo : style.homechef
            )}
          >
            <Player
              autoplay
              loop
              className={cn(style.basketRedesignImg, style.desktop)}
              alt={title}
              src={dskImg}
            />
            <Player
              autoplay
              loop
              className={cn(style.basketRedesignImg, style.mobile)}
              alt={title}
              src={mblImg}
            />
          </div>
        </div>
        <div className={style.basketRedesignContent}>
          <h1 className={cn(largeHeader ? 'mb-1 mt-5' : 'mb-2 mt-4', 'h4 text-charcoal-100')}>
            {title}
          </h1>
        </div>
        <p
          className={cn('text whitespace-pre-wrap font-normal', isTempo && 'text-secondary')}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </section>
    );
  }

  return (
    <section className={style.section} ref={transitionRef}>
      <img className={cn(style.img, style.desktop)} alt={title} src={dskImg} />
      <img className={cn(style.img, style.mobile)} alt={title} src={mblImg} />
      <div className={style.border} />
      <h1
        className={cn(
          largeHeader ? 'mb-1 mt-5' : 'mb-2 mt-4',
          isTempo ? 'h4 text-tempo-blue-90' : `${largeHeader ? 'h4' : 'h5'} text-green-100`
        )}
      >
        {title}
      </h1>
      <p className={cn('text font-normal', isTempo && 'text-secondary')}>{description}</p>
    </section>
  );
};

ModalSlide.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  dskImg: PropTypes.string.isRequired,
  isBasketRedesign: PropTypes.bool,
  mblImg: PropTypes.string.isRequired,
  largeHeader: PropTypes.bool,
  transitionRef: PropTypes.object,
};

export default ModalSlide;
