import cn from 'classnames';

const StepIndicator = ({ isTempo = false, currentStep, numSteps, stepOnClick }) => {
  return (
    <div className="flex">
      {[...Array(numSteps).keys()].map(step => {
        const dot = (
          <div
            key={step}
            className={cn('h-2 w-2 rounded-full', {
              'mr-2': step !== numSteps - 1,
              'bg-charcoal-60': step != currentStep,
              'bg-green-100': !isTempo && step === currentStep,
              'bg-tempo-blue-90': isTempo && step === currentStep,
            })}
            data-testid={`step-${step}`}
          />
        );

        if (step !== currentStep) {
          return (
            <button className="outline-none" onClick={() => stepOnClick(step)} key={step}>
              {dot}
            </button>
          );
        }

        return dot;
      })}
    </div>
  );
};

StepIndicator.propTypes = {
  currentStep: PropTypes.number.isRequired,
  numSteps: PropTypes.number.isRequired,
  stepOnClick: PropTypes.func.isRequired,
  isTempo: PropTypes.bool,
};

export default StepIndicator;
